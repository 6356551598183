import Head from 'next/head'
import '../styles/globals.css'

function MyApp ({ Component, pageProps }) {
  return (
    <>
      <Head>
        <title>Mastermine</title>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='keywords' content='minesweeper, mastermine, app, 3d, game' />
        <meta name='author' content='Adam Soutar' />
        <meta
          name='description'
          content='Mastermine is a 3d minesweeper inspired puzzle game'
          key='description'
        />
      </Head>
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
